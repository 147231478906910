export enum SocureKycStatus {
  // Socure statuses
  ACCEPT = 'accept',
  REJECT = 'reject',
  REFER = 'refer',
  RESUBMIT = 'resubmit',
  REVIEW = 'review',
  // custom statuses
  MISSING = 'missing', // user hasn't go thorough KYC yet
  DOCV_IN_PROGRESS = 'docv_in_progress', // user uploaded documents for DocV, waiting for a decision to hit a webhook
}

export enum APIUserRole {
  ADMIN = 'admin',
  USER = 'user',
  COMMISSIONER = 'commissioner',
}

type APIAuthUserResponse = {
  id: string; // UUID
  data: {
    id: string; // UUID
    username: string;
    handle?: string;
    avatarUrl?: string;
    email: string;
    emailVerified: boolean;
    referralCode: string;
  };
};

type APIAuthUserData = APIAuthUserResponse['data'];

type APIUserResponse = {
  id: string; // UUID
  data: {
    id: string; // UUID
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    mobileNumber: string;
    dateOfBirth: string; // Date-time
    verificationStatus: SocureKycStatus;
    status: string;
    createdAt: string; // Date-time
    timeout: {
      id: string;
      duration: number;
      createdAt: string; // Date-time
      expiresAt: string; // Date-time
    };
    isCommissioner?: boolean; // Doesn't exist in the payments-service yet
    marketingOptedIn?: boolean;
    vippRegistrationId?: string;
    role?: APIUserRole;
  };
};

type User = APIAuthUserResponse['data'] & APIUserResponse['data'];

export type UserSsnStatus = 'provided' | 'required' | 'not_required';

export type APIUserSsnStatusResponse = {
  id: string;
  data: {
    userSsnStatus: UserSsnStatus;
  };
};

export type { APIAuthUserResponse, APIAuthUserData, APIUserResponse, User };
